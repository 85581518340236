document.addEventListener("DOMContentLoaded", () => {
    let playBnts = document.querySelectorAll("[data-play]");
    
    playBnts.forEach(btn => {
        btn.addEventListener("click", () => {
            let preview = btn.closest(".faq-video__preview");
            let video = document.querySelector(`[data-play-video="${btn.dataset.play}"]`);

            console.log(video);

            preview.classList.add('faq-video__preview_hide');
            video.play();
        })
    })
})

document.addEventListener("DOMContentLoaded", () => {
    let accordions = document.querySelectorAll(".faq-accordion-item");

    accordions.forEach(accordion => {
        accordion.addEventListener("click", () => {
            let content = accordion.querySelector(".faq-accordion-item-content");
            let contentInner = accordion.querySelector(".faq-accordion-item-content-inner");

            if (accordion.classList.contains("faq-accordion-item-active")) {
                accordion.classList.remove("faq-accordion-item-active");
                content.style.maxHeight = `0px`;
            } else {
                accordion.classList.add("faq-accordion-item-active");
                content.style.maxHeight = `${contentInner.offsetHeight}px`
            }
        })
    })
})