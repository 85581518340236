document.addEventListener("DOMContentLoaded", () => {
    let openMenu = document.querySelector(".header__mobile-menu-open");
    let closeMenu = document.querySelector(".header__mobile-menu-close");
    let menu = document.querySelector(".header__mobile-menu");
    let menuItems = document.querySelectorAll(".header__mobile-nav ul li a");

    openMenu.addEventListener("click", () => {
        closeMenu.style.display = "flex";
        openMenu.style.display = "none";

        menu.classList.add("header__mobile-menu_active");
    });

    closeMenu.addEventListener("click", () => {
        closeMenu.style.display = "none";
        openMenu.style.display = "flex";

        menu.classList.remove("header__mobile-menu_active");
    });
    
    menuItems.forEach(item => {
        item.addEventListener("click", () => {
            closeMenu.style.display = "none";
            openMenu.style.display = "flex";

            menu.classList.remove("header__mobile-menu_active");
        })
    })
})